import React from 'react';
import { Layout } from 'antd';

const { Header, Content } = Layout;

class CadastroClientePage extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
    }

    
  componentDidMount() {
    document.title = "Unimake Software - Cadastro";
  }

    render() {
        return (<div>
            <Content style={{ padding: '10px 10px', marginTop: 64 }}>




            </Content>
        </div>
        );
    }
}
export default CadastroClientePage;