import React from 'react';
import { Layout, Tabs } from 'antd';

const { Header, Content } = Layout;
const { TabPane } = Tabs;

class IndexClientePage extends React.Component<any, any> {
    state = {
        
    }

    constructor(props: any) {
        super(props);
    }

    private callback(key) {
        console.log(key);
    }


    componentDidMount() {
        document.title = "Unimake Software - Área do Cliente";
    }

    render() {
        return (<div>
            <div className="content-area">
                <Tabs tabPosition="left" defaultActiveKey="1" onChange={this.callback}>
                    <TabPane tab="Licenças" key="1">

                    </TabPane>
                    <TabPane tab="Boletos" key="2">

                    </TabPane>
                    <TabPane tab="Notas Fiscais" key="3">

                    </TabPane>
                    <TabPane tab="Compras" key="4">

                    </TabPane>
                </Tabs>
            </div>
        </div>
        );
    }
}
export default IndexClientePage;