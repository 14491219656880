import React from 'react';
import './UmkBubbleMenu.scss';

import df from '../../assets/img/glyphs/df.png';
import unidanfe from '../../assets/img/glyphs/unidanfe.png';
import unico from '../../assets/img/glyphs/unico.png';
import gemini from '../../assets/img/glyphs/gemini.png';
import uninfe from '../../assets/img/glyphs/uninfe.png';

class UmkBubbleMenu extends React.Component<any, any> {
    static defaultProps = {
        Buttons: []
    }

    constructor(props: any) {
        super(props);
        this.toggleClass = this.toggleClass.bind(this);
        this.state = {
            active: true,
        };
    }

    toggleClass() {
        const currentState = this.state.active;
        this.setState({ active: !currentState });
    };

    render() {
        return (
            <div className="bubble">
                <div className={this.state.active ? 'menu active' : 'menu'} onClick={this.toggleClass} >
                    <div className="btn trigger">
                        <span className="line"></span>
                    </div>
                    <div className="icons">
                        <div className="rotater">
                            <div className="btn btn-icon">
                                <img className="img-bubble" src={df} />
                            </div>
                        </div>
                        <div className="rotater">
                            <div className="btn btn-icon">
                                <img className="img-bubble" src={unidanfe} />
                            </div>
                        </div>
                        <div className="rotater">
                            <div className="btn btn-icon">
                                <img className="img-bubble-unico" src={unico} />
                            </div>
                        </div>
                        <div className="rotater">
                            <div className="btn btn-icon">
                                <img className="img-bubble-gemini" src={gemini} />
                            </div>
                        </div>
                        <div className="rotater">
                            <div className="btn btn-icon">
                                <img className="img-bubble-uninfe" src={uninfe} />
                            </div>
                        </div>                     
                    </div>
                </div>
            </div>
        );
    }
}
export default UmkBubbleMenu;