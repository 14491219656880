import React from 'react';
import './UmkSocialFooter.scss';

class UmkSocialFooter extends React.Component<any, any> {
    static defaultProps = {
        FooterItens: []
    }

    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div>
                <ul className="social-link-footer list-unstyled">
                <li><a href="https://twitter.com/Unimake"><i className="fa fa-twitter"></i></a></li>                    
                <li><a href="https://www.youtube.com/channel/UCOSPmRhLgIFJU-pAy8Sogdw"><i className="fa fa-youtube"></i></a></li>
                <li><a href="https://www.linkedin.com/company/unimake-software/"><i className="fa fa-linkedin"></i></a></li>
                <li><a href="https://www.facebook.com/unimake/"><i className="fa fa-facebook"></i></a></li>                    
                <li><a href="https://blog.unimake.com.br/"><i className="fa fa-rss"></i></a></li>                    
                </ul>
            </div>
        );
    }
}
export default UmkSocialFooter;