import React from 'react';
import UmkAbout from '../../components/unimake-about/UmkAbout';
import { Layout } from 'antd';
import UmkProductDetail from '../../components/unimake-product-detail/UmkProductDetail';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

const { Header, Content } = Layout;

class SobrePage extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    private tab;

    static state = {
        tab: "historia"
    }

    componentDidMount() {
        document.title = "Unimake Software - Sobre";
    }

    componentWillMount() {        
        this.setState({
            tab: this.props.match.params.tabParam
        });
    }

 
    render() {
        return (<div>
            <div className="content-area">
                <UmkAbout tab={this.state.tab}></UmkAbout>
            </div>
        </div>
        );
    }
}
export default SobrePage;