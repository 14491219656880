import React, { Component } from 'react';
import { Drawer, Button } from 'antd';

import LeftMenu from '../unimake-navbar/UmkNavbar'
import RightMenu from '../unimake-rightnav/UmkRightNav'

import './UmkMainNav.scss';
import logo from '../../assets/img/unimake-logo.png';
import LazyLoad from 'react-lazyload';

class UmkMainNav extends Component {
    state = {
        current: 'mail',
        visible: false
    }
    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };
    onClose = () => {
        this.setState({
            visible: false,
        });
    };
    render() {
        return (
            <nav>
                <div className="logo">
                    <a href="">
                        <LazyLoad>
                            <img className="logoImage" src={logo} />
                        </LazyLoad>
                    </a>
                </div>
                <div className="menuCon">
                    <div className="leftMenu">
                        <LeftMenu />
                    </div>

                    <div className="rightMenu">
                        <RightMenu />
                    </div>
                </div>
                <Button className="barsMenu" type="primary" onClick={this.showDrawer}>
                    <i className="fa fa-bars bar-menu"></i>
                </Button>
                <Drawer
                    title="Menu"
                    placement="right"
                    closable={false}
                    onClose={this.onClose}
                    visible={this.state.visible}
                >
                    <LeftMenu />
                    <RightMenu />
                </Drawer>
            </nav>
        );
    }
}
export default UmkMainNav;