import { Component } from "react";

export default class MenuNavigation extends Component {

    /**
     * Abre uma página na navegação
     * @param page Página a abrir
     */
    public OpenPage(page: any) {
        return (
            page
        );
    }
}