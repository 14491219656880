import React from 'react';
import { Layout, Row, Col } from 'antd';
import { Parallax, Background } from 'react-parallax';
import UmkFeature from '../../../components/unimake-feature/UmkFeature';

import './DanfeVIEW.scss';
import UmkSegment from '../../../components/unimake-segment/UmkSegment';

const { Header, Content } = Layout;

class DanfeVIEWPage extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
    }


    componentDidMount() {
        document.title = "Unimake Software - DanfeVIEW";
    }

    render() {
        return (<div>
            <Parallax strength={200}>
                <div className="pl-banner">
                </div>
                <Background >
                    <img src="https://www.unimake.com.br/danfeview/assets/img/bop.jpg" />
                </Background>
            </Parallax>

            <section>
                <UmkSegment />
            </section>

            <br/>

            <section>
                {/* Frase */}
                <p className="phrase-product">
                    SOLUÇÕES QUE RESOLVEM OS SEUS PROBLEMAS
                </p>
            </section>
            
            <br/>

            <section>
                {/* Imagem */}
                <h3>
                    Imagem Aqui
                </h3>
            </section>

            <section>
                {/* Feature Area */}
                <div className="content-area">
                    <Row>
                        <Col sm={8} md={8}>
                            <UmkFeature Title="Lorem Title" Content="Lorem Content" IconSrc="http://www.unimake.com.br/gemini/assets/img/device20-512.png" />
                        </Col>

                        <Col sm={8} md={8}>
                            <UmkFeature Title="Lorem Title" Content="Lorem Content" IconSrc="http://www.unimake.com.br/gemini/assets/img/device20-512.png" />
                        </Col>

                        <Col sm={8} md={8}>
                            <UmkFeature Title="Lorem Title" Content="Lorem Content" IconSrc="http://www.unimake.com.br/gemini/assets/img/device20-512.png" />
                        </Col>
                    </Row>
                </div>
            </section>

            <section>
                <Parallax strength={300}>
                    <div className="pl-banner-2">
                        <h3>CURABITUR ULLAMCORPER ULTRICIES</h3>
                        <p>
                            Nunc lacinia ante nunc ac lobortis. Interdum adipiscing gravida odio porttitor sem non mi integer non faucibus ornare mi ut ante amet placerat aliquet. Volutpat eu sed ante lacinia sapien lorem accumsan varius montes viverra nibh in adipiscing. Lorem ipsum dolor vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis
                            volutpat ac adipiscing sed feugiat eu faucibus. Integer ac sed amet praesent. Nunc lacinia ante nunc ac gravida.
                        </p>
                    </div>
                    <Background >
                        <img src="https://www.unimake.com.br/danfeview/assets/img/bop.jpg" />
                    </Background>
                </Parallax>
            </section>



            <section>
                {/* Review Area */}
                <div className="content-area">
                    <p>Review Section</p>
                </div>
            </section>

        </div>
        );
    }
}
export default DanfeVIEWPage;