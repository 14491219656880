import React, { Component } from 'react';
import './UmkAbout.scss';
import { Menu, Row, Col } from 'antd';
import { Tabs, Button } from 'antd';

const { TabPane } = Tabs;


class UmkAbout extends React.Component<any, any> {
    state = {
        activeTab: "historia"
    }
    
    constructor(props: any) {
        super(props);        
    }

    changeTab = key => {
        this.setState({
            activeTab: key
        });
    }

    componentWillMount() {        
        this.setState({
            activeTab: this.props.tab
        });
    }


    render() {
        return (
            <div>
                <Tabs onChange={this.changeTab} activeKey={this.state.activeTab} tabPosition="left">
                    <TabPane tab="História" key="historia">
                        <h3>História</h3>

                        <p>
                            A Unimake Software foi criada em outubro de 2000, resultante da fusão das empresas Tendência Informática e LW Softwares, duas software houses atuantes na região noroeste do Paraná ambas fundadas em meados da década de 90.
                        
                        Inicialmente fornecendo sistemas integrados de Gestão (ERP), a empresa conquistou centenas de clientes nos segmentos de varejo e agroindústria em diversas regiões do país.
                        
                        O empenho e a busca constante por melhoria de seus fundadores fez com que ao longo de sua história a Unimake atraísse cada vez mais pessoas compromissadas com a qualidade e a seriedade em fornecer software para gestão, sem dúvida a ferramenta mais importante de qualquer empresa.
                        
                        Em 2005 seu principal software de gestão, o Único, foi compatibilizado com o sistema Windows® e em seguida, em 2006, o antigo banco de dados DBF foi substituído pelo moderno PostgreSQL, um dos mais modernos e difundidos bancos de dados do mundo.
                        
                        Em 2008, a chegada da Nota Fiscal Eletrônica (NF-e) abre novos mercados e oportunidades de inovação para a empresa, que lança diversos aplicativos ligados ao tema, dentre eles destaque para o Uninfe, primeiro aplicativo open source (código-livre e gratuito) para transmissão dos arquivos XML da NF-e, que rapidamente atraiu o interesse do mercado, sendo adotado por milhares de empresas de software no país.
                        
                        A história da Unimake não acaba aqui, muito pelo contrário, apenas começa, e seu sucesso só é possível graças aos colaboradores, aos clientes e aos parceiros que ajudam a escrevê-la a cada novo dia.
                    </p>
                    </TabPane>
                    <TabPane tab="Missão, Visão e Valores" key="missao">
                        <h3>Missão, Visão e Valores</h3>

                        <p>
                            Missão:
                       Desenvolver softwares que melhorem a vida das pessoas.
                       
                       Visão:
                       Tornar-se uma grande empresa do setor, desenvolvendo pessoas e fornecendo tecnologia como instrumento de geração de valor.
                       
                       Valores:
                       • Desenvolvimento e valorização das pessoas.
                       • Seriedade no relacionamento com nossos clientes.
                       • Rentabilidade das operações.
                       • Integridade no manuseio de informações.
                    </p>
                    </TabPane>
                    <TabPane tab="Parceiros" key="parceiros">
                        <h3>Parceiros</h3>

                        {/* ParceirosComponent */}
                    </TabPane>
                    <TabPane tab="Relações Institucionais" key="relacoes">
                        <h3>Parceiros</h3>

                        {/* ParceirosComponent */}
                    </TabPane>
                    <TabPane tab="Tecnologias" key="tecnologias">
                        <h3>Tecnologias</h3>

                        {/* TecnologiasComponent */}
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}
export default UmkAbout;