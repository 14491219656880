import React from 'react';
import {
    Layout, Form, Icon, Input, Button, Checkbox,
} from 'antd';


const { Header, Content } = Layout;

class LoginClientePage extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
    }


    componentDidMount() {
        document.title = "Unimake Software - Login";
    }

    render() {
        return (<div>
            <Content style={{ padding: '10px 10px', marginTop: 64 }}>

                <Form className="login-form">
                    <Form.Item>

                        <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="CPF" />

                    </Form.Item>
                    <Form.Item>

                        <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Senha" />
                    </Form.Item>
                    <Form.Item>
                        <Checkbox>Lembrar</Checkbox>
                        <a className="login-form-forgot" href="">Esqueci minha senha</a>  Ou <a href="">Cadastrar</a>
                        <br />
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Entrar
                </Button>
                    </Form.Item>
                </Form>


            </Content>
        </div>
        );
    }
}
export default LoginClientePage;