import React, { Component } from 'react';
import './UmkSupportLine.scss';
import { Row, Col, Icon } from 'antd';

class UmkSupportLine extends React.Component<any, any> {
    state = {
        Telefone: '',
        Email: '',
        LinkSuporte: '',
        Background: ''
    }

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.setState({
            Telefone: this.props.Telefone,
            Email: this.props.Email,
            LinkSuporte: this.props.LinkSuporte,
            Background: this.props.Background
        });
    }

    render() {
        return (
            <div>
                <div style={{ backgroundColor: this.state.Background }} className="support-line">
                    <Row>
                        <Col md={8} sm={8}>
                            <div className="spp-area">
                                <Icon className="spp-icon center" type="phone" />
                                <p>
                                    <span className="spp-title">Atendimento</span>
                                    <br />
                                    <span className="spp-content" >{this.state.Telefone}</span>
                                </p>
                            </div>
                        </Col>

                        <Col md={8} sm={8}>
                            <div className="spp-area">
                                <Icon className="spp-icon center" type="question" />
                                <p>
                                    <span className="spp-title">Suporte</span>
                                    <br />
                                    <span className="spp-content">
                                        <a href={this.state.LinkSuporte}>Suporte via Chat</a>
                                    </span>
                                </p>
                            </div>
                        </Col>

                        <Col md={8} sm={8}>
                            <div className="spp-area">
                                <Icon className="spp-icon center" type="mail" />
                                <p>
                                    <span className="spp-title">E-mail</span>
                                    <br />
                                    <span className="spp-content" >
                                        <a href={'mailto:' + this.state.Email}> {this.state.Email} </a>                                    
                                    </span>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}
export default UmkSupportLine;