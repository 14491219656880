import React, { Component } from 'react';
import { Menu, Popover, Icon } from 'antd';
import UmkCartSimple from '../unimake-cart-simple/UmkCartSimple';

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class UmkRightNav extends Component {
    private content = <UmkCartSimple style={{ width: '120px' }} />;

    render() {
        return (
            <Menu mode="horizontal" style={{ lineHeight: '64px' }}>
                <Menu.Item key="cart">
                    <Popover placement="bottom" content={this.content} title="Carrinho">
                        <Icon type="shopping-cart" /> Carrinho
                    </Popover>
                </Menu.Item>
                <Menu.Item key="login">
                    <a href=""><Icon type="user" /> Login</a>
                </Menu.Item>
                <Menu.Item key="cadastro">
                    <a href=""> <Icon type="user-add" /> Cadastro</a>
                </Menu.Item>
            </Menu>
        );
    }
}
export default UmkRightNav;