import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Layout } from 'antd';
import MenuNavigation from './Services/MenuNavigation';

import './App.scss';
import './App.css';

import HomePage from './Pages/Home/HomePage';
import SobrePage from './Pages/Sobre/SobrePage';

import TrabalheConoscoPage from './Pages/TrabalheConosco/TrabalheConoscoPage';
import UmkMainNav from './components/unimake-mainnav/UmkMainNav';
import UmkFooter from './components/unimake-footer/UmkFooter';
import UmkGoTop from './components/unimake-gotop/UmkGoTop';
import ContatoPage from './Pages/Contato/ContatoPage';
import ProdutosPage from './Pages/Produtos/ProdutosPage';
import DanfeVIEWPage from './Pages/products/DanfeVIEW/DanfeVIEWPage';
import DownloadsPage from './Pages/Downloads/DownloadsPage';
import CadastroClientePage from './Pages/ClienteArea/CadastroClientePage';
import LoginClientePage from './Pages/ClienteArea/LoginClientePage';
import CarrinhoPage from './Pages/Loja/CarrinhoPage/CarrinhoPage';
import IndexClientePage from './Pages/ClienteArea/IndexClientePage';


const { Header, Content } = Layout;

class App extends Component {
  private Menu: MenuNavigation;

  constructor(props: any) {
    super(props);

    this.Menu = new MenuNavigation(props);
  }

  render() {
    return (
      <Layout>
        <Router>

          <Header className="main-header">
            <UmkMainNav />
          </Header>

          <Content className="main-content">

            <Route exact path="/" component={this.Menu.OpenPage(HomePage)} />            
            <Route path="/sobre/:tabParam" component={this.Menu.OpenPage(SobrePage)} />
            <Route path="/trabalhe-conosco" component={this.Menu.OpenPage(TrabalheConoscoPage)} />            
            <Route path="/contato" component={this.Menu.OpenPage(ContatoPage)} />
            <Route path="/downloads" component={this.Menu.OpenPage(DownloadsPage)} />

            <Route path="/produtos" component={this.Menu.OpenPage(ProdutosPage)} />
            <Route path="/produto/danfeview" component={this.Menu.OpenPage(DanfeVIEWPage)} />

            {/* Cliente  */}
            <Route path="/cadastro" component={this.Menu.OpenPage(CadastroClientePage)} />
            <Route path="/login" component={this.Menu.OpenPage(LoginClientePage)} />
            <Route path="/cliente/dashboard" component={this.Menu.OpenPage(IndexClientePage)} />

            {/* Loja */}
            <Route path="/carrinho" component={this.Menu.OpenPage(CarrinhoPage)} />
            
          </Content>

          
          <UmkGoTop />
          <UmkFooter />

        </Router>
      </Layout>
    );
  }
}

export default App;
