import React, { Component } from 'react';
import Slider from "react-slick";
import './UmkSlider.scss';

class UmkSlider extends React.Component<any, any> {
    static defaultProps = {
        SlideItens: []
    }

    constructor(props: any) {
        super(props);
    }

    render() {        
        var settings = {
            autoplay: true,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };        

        return (
            <div className="main-slider">
                <Slider {...settings}>
                    {
                        this.props.SlideItens.map((item: any) => <div> <img className="slick-image" src={item.image} /> <h3 className="slick-caption"> {item.caption} </h3> </div>)
                    }
                </Slider>
            </div>
        );
    }
}
export default UmkSlider;