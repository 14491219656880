import React from 'react';
import './UmkFeature.scss';

class UmkFeature extends React.Component<any, any> {
    static defaultProps = {
        IconSrc: '',
        Content: '',
        Title: ''
    }

    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div className="feature-box">
                <div className="feature-box-heading">
                    <div> 
                        <img className="center" src={this.props.IconSrc} width="100" height="100" alt={this.props.Title} />
                    </div>
                    <h4 className="text-center">
                        <b className="title-feature">{this.props.Title}</b>
                    </h4>
                </div>
                <p className="text-center text-feature">
                    {this.props.Content}
                </p>
            </div>
        );
    }
}
export default UmkFeature;