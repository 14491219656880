import React, { Component } from 'react';
import { Layout, Row, Col, Card } from 'antd';
import { Parallax, Background } from 'react-parallax';
import './ProdutosPage.scss';

import ImageService from '../../assets/img/service.jpg';

import UmkBubbleMenu from '../../components/unimake-bubble-menu/UmkBubbleMenu';

class ProdutosPage extends React.Component<any, any> {
  static state = {

  }

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    document.title = "Unimake Software - Produtos";
  }

  render() {
    return (
      <div>
        <section style={{backgroundColor: '#000'}}>
          {/* Bubble Menu In Parallax */}
            <Parallax strength={200}>
              <div className="pl-banner">
              </div>
              <UmkBubbleMenu />
              <Background >
                {/* <img className="bg-img" src={ImageService} /> */}
              </Background>
            </Parallax>            
        </section>
      </div>
    );
  }
}
export default ProdutosPage;