import React from 'react';
import {
  Layout,
  Form,
  Input,
  Tooltip,
  Icon,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  Tabs,
} from 'antd';

const { Header, Content } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;
const AutoCompleteOption = AutoComplete.Option;

class ContatoPage extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
  }


  componentDidMount() {
    document.title = "Unimake Software - Contato";
  }

  render() {

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    


    return (<div>
      <div className="content-area">
        <Row>
          <Col sm={11} md={11}>
            <Form {...formItemLayout} >
              <Form.Item label="E-mail">
                
              </Form.Item>


              <Form.Item
                label={
                  <span>
                    Nickname&nbsp;
                          <Tooltip title="What do you want others to call you?">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
              
              </Form.Item>


              <Form.Item label="Phone Number">
              
              </Form.Item>


              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                  Enviar
                </Button>
              </Form.Item>
            </Form>
          </Col>

          <Col sm={2} md={2}>
          </Col>

          <Col sm={8} md={8}>
            <h3>Endereço</h3>

            <div>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Tab 1" key="1">
                  Content of Tab Pane 1
                </TabPane>
                <TabPane tab="Tab 2" key="2">
                  Content of Tab Pane 2
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    );
  }
}
export default ContatoPage;