import React, { Component } from 'react';
import { Layout, Row, Col, Card } from 'antd';

import './HomePage.scss';
import UmkBubbleMenu from '../../components/unimake-bubble-menu/UmkBubbleMenu';
import UmkFeature from '../../components/unimake-feature/UmkFeature';

import unico from '../../assets/img/glyphs/unico.png';
import unidanfe from '../../assets/img/glyphs/unidanfe.png';
import danfeview from '../../assets/img/glyphs/df.png';

import { Link } from 'react-router-dom';
import UmkProductCard from '../../components/unimake-product-card/UmkProductCard';
import UmkSlider from '../../components/unimake-slider/UmkSlider';
import UmkSupportLine from './../../components/unimake-support-line/UmkSupportLine';


const { Header, Content } = Layout;

class HomePage extends React.Component<any, any> {
  state = {
    SlideItens: [
      {
        image: require('../../assets/img/slide/001.jpg')
      },
      {
        image: require('../../assets/img/slide/002.jpg')
      }
    ]
  }

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    document.title = "Unimake Software - Home";
  }

  render() {
    return (
      <div>
        <section>
          <UmkSlider className="principal-slider" SlideItens={this.state.SlideItens} /> 
        </section>

        <section>
          <div className="content-area">
            <Row>
              <Col sm={8} md={8}>
                <div className="product-card">
                  <UmkProductCard
                    img={unico}
                    colorHex="#0f8c84"
                    routePath="produtos/unico"
                    content="Lorem Impsum dolor sit"
                    title="Unico"
                    buttonText="Saiba mais"
                    buttonRoute="produtos/unico"
                  >
                  </UmkProductCard>
                </div>
              </Col>

              <Col sm={8} md={8}>
                <div className="product-card">
                  <UmkProductCard
                    className="product-card"
                    img={danfeview}
                    colorHex="#d14e24"
                    routePath="produtos/danfeview"
                    content="Lorem Impsum dolor sit"
                    title="DANFE View"
                    buttonText="Saiba mais"
                    buttonRoute="produtos/danfeview"
                  >
                  </UmkProductCard>
                </div>
              </Col>

              <Col sm={8} md={8}>
                <div className="product-card">
                  <UmkProductCard
                    className="product-card"
                    img={unidanfe}
                    colorHex="#0756a3"
                    routePath="produtos/unidanfe"
                    content="Lorem Impsum dolor sit"
                    title="UniDANFE"
                    buttonText="Saiba mais"
                    buttonRoute="produtos/unidanfe"
                  >
                  </UmkProductCard>
                </div>
              </Col>
            </Row>
          </div>
        </section>

        <section>
          <Row>
            <Col md={8} sm={8}>
              <h3>Unimake Software</h3>

              <p>
                Lorem ipsum dot sit
              </p>

            </Col>
            <Col md={2} sm={2} />
            <Col md={14} sm={14}>
              
            </Col>
          </Row>
        </section>

        <section>
          {/* Feature Area */}
          <div className="content-area">
            <Row>
              <Col sm={8} md={8}>
                <UmkFeature Title="Lorem Title" Content="Lorem Content" IconSrc="http://www.unimake.com.br/gemini/assets/img/device20-512.png" />
              </Col>

              <Col sm={8} md={8}>
                <UmkFeature Title="Lorem Title" Content="Lorem Content" IconSrc="http://www.unimake.com.br/gemini/assets/img/device20-512.png" />
              </Col>

              <Col sm={8} md={8}>
                <UmkFeature Title="Lorem Title" Content="Lorem Content" IconSrc="http://www.unimake.com.br/gemini/assets/img/device20-512.png" />
              </Col>
            </Row>
          </div>
          {/* Nossos Produtos */}
        </section>

        <section>
          <UmkSupportLine
            Telefone="(44) 3141-4900"
            Email="contato@unimake.com.br"
            LinkSuporte=""
            Background="#136295"
          />
        </section>

        <section>
          {/* Widgets */}
        </section>

        
      </div>
    );
  }
}
export default HomePage;