import React from 'react';
import { Layout, Row, Col } from 'antd';
import UmkCurriculoLogin from '../../components/unimake-curriculo-login/UmkCurriculoLogin';
import UmkVagasList from '../../components/unimake-vagas-list/UmkVagasList';

const { Header, Content } = Layout;

class TrabalheConoscoPage extends React.Component<any, any> {
    state = {

    }

    constructor(props: any) {
        super(props);
    }

    private Vagas = [
        {
            Title: 'Vendedor',
            Content: `Área: Comercial
            Atividades:
            
            Prospecção de novos clientes.
            Relacionamento com clientes já existentes.
            Venda de aplicativos por telefone ou pessoalmente.
            
             
            
            Requisitos:
            
            Importante ter atuação prévia com vendas (preferencialmente software ou serviços).
            Proatividade e habilidade de negociação.
            Habilitação B.
            
            O profissional deverá residir em Campo Mourão ou Paranavai.

            `
        }
    ]


    componentDidMount() {
        document.title = "Unimake Software - Trabalhe Conosco";
    }

    render() {
        return (<div>
            <section>
                <div className="content-area">
                    <Row>
                        <Col sm={11} md={11}>
                            <UmkVagasList
                                Vagas={this.Vagas}
                            />
                        </Col>
                        <Col sm={2} md={2}></Col>
                        <Col sm={10} md={10} >
                            <UmkCurriculoLogin />
                        </Col>
                    </Row>
                </div>
            </section>
        </div>
        );
    }
}
export default TrabalheConoscoPage;