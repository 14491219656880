import React from 'react';
import { Layout } from 'antd';

const { Header, Content } = Layout;

class DownloadsPage extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
    }

    
  componentDidMount() {
    document.title = "Unimake Software - Downloads";
  }

    render() {
        return (<div>
           <div className="content-area">

           </div>
        </div>
        );
    }
}
export default DownloadsPage;