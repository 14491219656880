import React from 'react';
import './UmkSegment.scss';
import { Row, Col, Button, Icon } from 'antd';
import { Link } from 'react-router-dom';

class UmkSegment extends React.Component<any, any> {
    state = {
        SegmentItens: []
    }

    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div className="unimake-segment">
                <Row>
                    <Col sm={6} md={6}></Col>
                    <Col sm={12} md={12}>
                        <Row>
                            <Col sm={4} md={4}>
                                <div className="button-segment">
                                    <Link to="">
                                        <Icon className="segment-icon center" type="play-circle" />
                                        <p>
                                            <span className="segment-text">Segment</span>
                                        </p>
                                    </Link>
                                </div>
                            </Col>

                            <Col sm={4} md={4}>
                                <div className="button-segment">
                                    <Link to="">
                                        <Icon className="segment-icon center" type="play-circle" />
                                        <p>
                                            <span className="segment-text">Segment</span>
                                        </p>
                                    </Link>
                                </div>
                            </Col>

                            <Col sm={4} md={4}>
                                <div className="button-segment">
                                    <Link to="">
                                        <Icon className="segment-icon center" type="play-circle" />
                                        <p>
                                            <span className="segment-text">Segment</span>
                                        </p>
                                    </Link>
                                </div>
                            </Col>

                            <Col sm={4} md={4}>
                                <div className="button-segment">
                                    <Link to="">
                                        <Icon className="segment-icon center" type="play-circle" />
                                        <p>
                                            <span className="segment-text">Segment</span>
                                        </p>
                                    </Link>
                                </div>
                            </Col>

                            <Col sm={4} md={4}>
                                <div className="button-segment">
                                    <Link to="">
                                        <Icon className="segment-icon center" type="play-circle" />
                                        <p>
                                            <span className="segment-text">Segment</span>
                                        </p>
                                    </Link>
                                </div>
                            </Col>

                            <Col sm={4} md={4}>
                                <div className="button-segment">
                                    <Link to="">
                                        <Icon className="segment-icon center" type="play-circle" />
                                        <p>
                                            <span className="segment-text">Segment</span>
                                        </p>
                                    </Link>
                                </div>
                            </Col>
                            
                        </Row>
                    </Col>
                    <Col sm={6} md={6}></Col>
                </Row>
            </div>
        );
    }
}
export default UmkSegment;