import React, { Component } from 'react';
import './UmkCurriculoLogin.scss';
import {
    Form, Icon, Input, Button, Checkbox,
} from 'antd';

class UmkCurriculoLogin extends React.Component<any, any> {
    static defaultProps = {

    }

    constructor(props: any) {
        super(props);
    }

    handleSubmit = (e: any) => {
        e.preventDefault();
        this.props.form.validateFields((err: any, values: any) => {
            if (!err) {
                console.log('valores recebidos: ', values);
            }
        });
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item>

                    <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="CPF" />

                </Form.Item>
                <Form.Item>

                    <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Senha" />
                </Form.Item>
                <Form.Item>
                    <Checkbox>Lembrar</Checkbox>
                    <a className="login-form-forgot" href="">Esqueci minha senha</a>  Ou <a href="">Cadastrar</a>
                    <br />
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Entrar
                </Button>
                </Form.Item>
            </Form>
        );
    }


}
export default UmkCurriculoLogin;