import React from 'react';
import './UmkCartSimple.scss';

class UmkCartSimple extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div>
                <table>                    
                    <thead>
                        <tr>
                            <th scope="col">Produto</th>
                            <th scope="col">Valor</th>
                            <th scope="col">Validade</th>
                            <th scope="col">Quantidade</th>                            
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td data-label="Produto">DANFE View Plus</td>
                            <td data-label="Valor">R$ 94,90</td>
                            <td data-label="Validade">1 Ano</td>
                            <td data-label="Quantidade">1</td>               
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
export default UmkCartSimple;