import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './UmkFooter.scss';
import { Row, Col } from 'antd';
import UmkSocialFooter from '../unimake-socialfooter/UmkSocialFooter';
import { Link } from 'react-router-dom';

class UmkFooter extends Component {

    render() {
        return (
            <div>
                <footer className="footer">
                    <div className="container">
                        <Row>
                            <Col className="pl-10" sm={1} md={1} />
                            <Col className="pl-5" sm={6} md={6}>
                                <div className="contact-area">
                                    <h1>
                                        Contato
                                    </h1>
                                    <address>
                                        <p><i className="fa fa-globe pr-10"></i>Campo Mourão: Av. Com. Norberto Marcondes, 2156</p>
                                        <p><i className="fa fa-globe pr-10"></i>Paranavaí: Rua Antônio Felipe, 1500</p>
                                        <p><i className="fa fa-phone pr-10"></i>(44) 3141-4900</p>
                                        <p><i className="fa fa-envelope pr-10"></i> <a href="mailto:comercial@unimake.com.br">comercial@unimake.com.br</a></p>
                                    </address>
                                </div>
                            </Col>
                            <Col className="about-column pr-15" sm={4} md={4}>
                                <div className="our-company-area">
                                    <h1>
                                        Quem Somos
                                    </h1>
                                    <ul className="page-footer-list">
                                        <li>
                                            <i className="fa fa-angle-right"></i>
                                            <Link to="/sobre/historia">História</Link>
                                        </li>
                                        <li>
                                            <i className="fa fa-angle-right"></i>
                                            <Link to="/sobre/missao">Missão, Visão e Valores</Link>
                                        </li>
                                        <li>
                                            <i className="fa fa-angle-right"></i>
                                            <Link to="/sobre/parceiros">Parceiros</Link>
                                        </li>
                                        <li>
                                            <i className="fa fa-angle-right"></i>
                                            <Link to="/sobre/relacoes">Relaçoes Institucionais</Link>
                                        </li>
                                        <li>
                                            <i className="fa fa-angle-right"></i>
                                            <Link to="/sobre/tecnologias">Tecnologias</Link>
                                        </li>
                                        <li>
                                            <i className="fa fa-angle-right"></i>
                                            <Link to="/trabalhe-conosco">Trabalhe Conosco</Link>
                                        </li>
                                    </ul>
                                </div>
                            </Col>

                            <Col className="pl-10 pr-10" sm={4} md={4}>
                                <div className="our-company-area">
                                    <h1>
                                        Produtos
                                    </h1>
                                    <ul className="page-footer-list">
                                    <li>
                                            <i className="fa fa-angle-right"></i>
                                            <Link to="/produto/danfeview">DANFE View</Link>
                                        </li>
                                        <li>
                                            <i className="fa fa-angle-right"></i>
                                            <Link to="/produto/unidanfe">UniDANFE</Link>
                                        </li>
                                        <li>
                                            <i className="fa fa-angle-right"></i>
                                            <Link to="/produto/uninfe">UniNFe</Link>
                                        </li>
                                        <li>
                                            <i className="fa fa-angle-right"></i>
                                            <Link to="/produto/unico">Único</Link>
                                        </li>
                                        <li>
                                            <i className="fa fa-angle-right"></i>
                                            <Link to="/produtos">Mais Produtos</Link>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col sm={6} md={6} />
                        </Row>
                    </div>

                </footer>
                <footer className="footer-small">
                    <Row>
                        <Col className="pl-5" sm={8} md={8}>
                            <div className="copyright">
                                <p>&copy; Copyright
                                <script>document.write((new Date).getFullYear())</script> -
                                <a className="copyright-anchor" href="https://www.unimake.com.br" target="_blank">Unimake Software Todos os direitos reservados.</a>
                                </p>
                            </div>
                        </Col>
                        <Col sm={16} md={16}>
                            <UmkSocialFooter />
                        </Col>
                    </Row>
                </footer>
            </div>
        );
    }
}
export default UmkFooter;