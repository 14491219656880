import React, { Component } from 'react';
import { Collapse } from 'antd';

import './UmkVagasList.scss';

const Panel = Collapse.Panel;

class UmkVagasList extends React.Component<any, any> {
    static defaultProps = {
        Vagas: [],
        Title: 'Vagas Disponíveis'
    }

    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div>
                <h3 className="vagas-title">{this.props.Title}</h3>
                <Collapse accordion>
                    {
                        this.props.Vagas.map((item: any) => <Panel header={item.Title} key={item.Id}> <p> {item.Content} </p> </Panel>)
                    }
                </Collapse>
            </div>
        );
    }
}
export default UmkVagasList;