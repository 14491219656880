import React, { Component } from 'react';
import { Card, Button } from 'antd';

import './UmkProductCard.scss';
import { Link } from 'react-router-dom';


class UmkProductCard extends React.Component<any, any> {
    state = {
        img: '',
        colorHex: '',
        routePath: '',
        content: '',
        title: '',
        buttonText: '',
        buttonRoute: ''
    }

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.setState({
            img: this.props.img,
            colorHex: this.props.colorHex,
            routePath: this.props.routePath,
            content: this.props.content,
            title: this.props.title,
            buttonText: this.props.buttonText,
            buttonRoute: this.props.buttonRoute
        });
    }

    render() {
        return (
            <div>
                <Card className="card card-with-shadow" style={{ backgroundColor: this.state.colorHex }} data-background="color">
                    <div className="header header-with-icon">
                        <div className="icon">
                            <img src={this.state.img} className="center img-proje" />
                        </div>
                    </div>
                    <div className="content text-center">
                        <h4 className="title">
                            <Link to={this.state.routePath}>
                                {this.state.title}
                            </Link>
                        </h4>
                        <p className="description">
                            {this.state.content}
                        </p>
                    </div>
                    <div className="card-footer btn-center">
                        <Button block type="default" shape="round" size='large'>
                            <Link to={this.state.buttonRoute}>
                                {this.state.buttonText}
                            </Link>
                        </Button>
                    </div>
                </Card>
            </div>
        );
    }
}
export default UmkProductCard;