import React, { Component } from 'react';
import { Menu, Icon } from 'antd';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class UmkNavbar extends Component {
    render() {
        return (
            <Menu mode="horizontal"
                style={{ lineHeight: '64px' }}
            >
                <Menu.Item key="home">
                    <Link to="/">Home</Link>
                </Menu.Item>
                <Menu.Item key="produtos">
                    <Link to="/produtos">Produtos</Link>
                </Menu.Item>
                {/* <Menu.Item key="licencas">
                    <a href="/">Licenças</a>
                </Menu.Item> */}
                <Menu.Item key="downloads">
                    <Link to="/downloads">Downloads</Link>
                </Menu.Item>
                <Menu.Item key="trabalhe">
                    <Link to="/trabalhe-conosco">Tabalhe Conosco</Link>
                </Menu.Item>
                <SubMenu title={<span>Blogs</span>}>
                    <MenuItemGroup title="Blogs">
                        <Menu.Item key="blog:1">Gemini</Menu.Item>
                        <Menu.Item key="blog:2">UniNFe</Menu.Item>
                    </MenuItemGroup>
                </SubMenu>
                <Menu.Item key="contato">
                    <Link to="/contato">Contato</Link>
                </Menu.Item>
                <Menu.Item key="sobre">
                    <Link to="/sobre/historia">Sobre</Link>
                </Menu.Item>
            </Menu>
        );
    }
}
export default UmkNavbar;